const proxy = require('./devProxy');

const config = {
  // 签名密钥
  spk: '4ff22a35c78df1577480cb53dd785590',
  ThemeColor: '#FFE87B',
  lessModifyVars: {
    // less变量
    'primary-color': '#FFE87B',
    animationDelay: '0.6s cubic-bezier(0, 0, 0, 0.8)',
    maxWidth: '1528px',
  },
  domains: {
    test: 'local.manjuu.com',
    local: 'local.manjuu.com',
    dev: 'dev.manjuu.com',
    uat: 'qa.manjuu.com',
    ce: 'qa.manjuu.com',
    prod: 'manjuu.com',
  },
  // token 名称
  tk: 'x-token',
  CDN: `https://plat-cdn.manjuu.com/platform/OfficialWebsite/${process.env.APP_ENV}`,
  SENTRY_DSN: 'https://626776c8f5347b835def5f8cfe6f1ed5@sentry.manjuu.com/4',
  proxy,
  // 默认语言
  defaultLang: 'zh',
  lang: [
    'en', // 英语-海外默认语言
    'zh', // 大陆-简体中文
    'zh-tw', // 台湾-繁体中文
    'kr', // 韩语
    'jp', // 日语
  ],
};

module.exports = config;
